import { configureStore } from '@reduxjs/toolkit';
import { appReducer, AppState } from './reducers';

export interface RootState {
  app: AppState;
}

export const store = configureStore({
  reducer: {
    app: appReducer
  }
});
