import { useState, useEffect } from 'react';
import { computeUrlSafeHash } from '../common';
import {Screen, Page } from '../components';
import { useSelector } from 'react-redux';
import { AppState } from '../redux/reducers';

export function TestPage() {
    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
    // const [refresh, SetRefresh] = useState<boolean>(false);

    const username = useSelector((state: AppState) => state.user ? state.user.username : undefined);
    console.log(`UseSelector Username: ${username}`);

    useEffect(() => {
        const fetchTestPage = async () => {
            const queryString = window.location.search; 
            console.log(`Fetching test page: ${queryString}`);

            try {
                const input = `ashok-1724517503`;
                const hash = await computeUrlSafeHash(input);
                setErrorMsg(`${input}: Hash(${hash})`);
            } catch(error) {
                console.log(`Error fetching test page: ${error instanceof Error ? error.message : 'UNKNOWN ERROR'}`);
                setErrorMsg(`Unable to process your request. Please try after some time.`);
            }
        };

        fetchTestPage();
    }, []);

    return (
        <Screen>
            { errorMsg ?
                <Page> {errorMsg} </Page> : 'No Message'
            }
        </Screen>
    );
}