import { useState, useEffect, useCallback } from 'react';
import {AxiosError} from 'axios';
import { useNavigate } from 'react-router-dom';
import { Path, QueryString, Term, callApi, renewAccess, ResponseCode, OutOfBandData } from '../common';
import {Screen, Page, Banner, Tool, Spacer } from '../components';
import { useDispatch } from 'react-redux';
import { sessionActivated } from '../redux/reducers';
// import { RootState } from '../redux/store';

interface HomeObject {
    username: string,
    name: string,
    roles: string,
    message: string,
    outOfBandData: OutOfBandData,
}

export function HomePage() {
    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
    const [homeObj, setHomeObj] = useState<HomeObject | undefined>(undefined);
    // const [refresh, SetRefresh] = useState<boolean>(false);

    // let username = useSelector((state: RootState) => state.app.user ? state.app.user.username : undefined);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getHomePage = useCallback(async (sessionId: string) => {
        const queryString = window.location.search; 
        const query = new QueryString().add(Term.Session, sessionId).value();
        const response = await callApi(Path.HomeApi, query, undefined);
        // const response = await axios.get(`${baseUrl}${Path.HomeApi}${queryString}`);

        const status = response[Term.Status];
        if (status === ResponseCode.Success) {
            console.log(`Success: ${response[Term.Result]}`);
            const homeObj = response[Term.Result] as HomeObject;
            setErrorMsg(undefined);
            setHomeObj(homeObj);
            const outOfBandData = homeObj[Term.OutOfBandData];
            if (outOfBandData) {
                dispatch(sessionActivated(outOfBandData[Term.User]));
            }
        } else if (status === ResponseCode.AuthFailed) {
            console.log(`Home page: Auth Failed`);
            navigate(`${Path.SignInPage}${queryString}`);
        } else if (status === ResponseCode.Forbidden) {
            console.log(`Home page: Forbidden - should not happen`);
            navigate(`${Path.SignInPage}${queryString}`);
        } else {
            const axiosError = response[Term.Result] as AxiosError;
            const msg = axiosError.response ? `${axiosError.response.statusText} (${axiosError.response.status})` : 'Internal Server Error';
            console.log(`Error: ${msg}`);
            setErrorMsg(`Unable to process your request. Please try again after some time.`);
        }
    }, [navigate, dispatch, setErrorMsg, setHomeObj]);

    useEffect(() => {
        const fetchHomePage = async () => {
            console.log(`Fetching home page.`);

            try {
                const sessionNumber = await renewAccess(navigate);
                if (!sessionNumber) return; // i.e. the renewAccess method navigated us to sign in

                console.log(`Renewed session: ${sessionNumber}`);
                await getHomePage(sessionNumber);
            } catch(error) {
                console.log(`Error fetching home page: ${error instanceof Error ? error.message : 'UNKNOWN ERROR'}`);
                setErrorMsg(`Unable to process your request. Please try after some time.`);
            }
        };

        fetchHomePage();
    }, [navigate, getHomePage]);

    function handleShowProfile() {
        console.log(`Show user profile`);
    }

    const tools: Tool[] = [{
        name: 'Profile',
        img: '/img/profile.png',
        action: handleShowProfile,
    }]
    return (
        <Screen>
            { errorMsg ?
                <Page> {errorMsg} </Page> :
                ( homeObj ? 
                    <>
                        <Banner subtitle='MyVote' title='Election 2024' tools={tools}/>
                        <Page>
                            <h3>Welcome {homeObj.name}!</h3>
                            <Spacer size='3em' grow={true}/>
                            <h1>{homeObj.message}</h1>
                            <Spacer size='3em' grow={true}/>
                        </Page>
                    </>
                    : null
                )
            }
        </Screen>
    );
}