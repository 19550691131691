import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { BodyText, TextField, StandardButton, TextInputType, PageTitle, Panel, InputFieldRef, Screen } from '../components';
import { Path, QueryString, Term, AuthRequest, callApi, ResponseCode, ApiResponse } from '../common'

const forgotPasswordTitle = 'Forgot Password';
const forgotPrompt = 'USERNAME OR EMAIL';
const forgotInstrText = 'Username or Email: Please enter your username or email address to recover your account.';
const forgotHelpText = 'In order to recover your account, we will send a verification code to this email address. Once you verify the code, you will be able to retrieve your username and change the password.';

const forgotButtonText = 'Recover Account';
const forgotWorkingButtonText = 'Initiating Account Recovery..';

const errInput = 'Username or email entered is not valid. Please correct and try again.';
const errUsernameNotFound = 'The username provided was not found. Please correct and try again.';
const errInitiatingForgotPassword = 'We are sorry but an occurred while processing your request. Please try again after some time.';

export function ForgotPasswordPage() {
    console.log(`Forgot Password page.`);

    const navigate = useNavigate();

    const inputRef = useRef<InputFieldRef>(null);

    const queryString = window.location.search; 

    const authForgotPassword = async (emailOrUsername: string, isEmail: boolean): Promise<ApiResponse> => {
        console.log(`authForgotPassword`);

        // cmd=forgotPassword
        const query = new QueryString().add(Term.Request, AuthRequest.ForgotPassword).value();
        const data = {[isEmail ? Term.Email : Term.Username]: emailOrUsername};
        const response = await callApi(Path.AuthApi, query, data);

        // status: success,  
        return response;
    }

    const handleForgotPassword = async (): Promise<string | undefined> => {
        console.log(`handleForgotPassword`);
        let emailOrUsername = inputRef.current ? (inputRef.current.getValue().valid ? inputRef.current.getValue().value : undefined) : undefined;
        
        var isEmail = false;
        if (emailOrUsername === undefined) return errInput;

        emailOrUsername = emailOrUsername.trim().toLowerCase();
        if (emailOrUsername.includes('@')) {
            isEmail  = true;
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const valid = regex.test(emailOrUsername);
            if (!valid) return errInput;        
        }
        const response = await authForgotPassword(emailOrUsername, isEmail);
        const status = response[Term.Status];
        const result: {[key in Term]?: string} = response[Term.Result];
        if (status === ResponseCode.Success) {
            const stateObj: {[key in Term]? : string} = {
                [Term.Data]: result[Term.Username],
            }; 
            navigate(`${Path.UpdatePasswordPage}${queryString}`, {state: stateObj});
            return undefined;
        } else {
            var err = errInitiatingForgotPassword;
            switch (status) {
                case ResponseCode.UserDoesNotExist:
                    err = errUsernameNotFound;
                    break;
            }
            return err;
        }
    }

    return (
        <Screen>
            <Panel spacing='3em'>
                <PageTitle title={forgotPasswordTitle}/>
                <div>
                    <TextField ref={inputRef} required={true} textInputType={TextInputType.text} placeholder={forgotPrompt} instr={forgotInstrText}/>
                    <BodyText text={forgotHelpText}/>
                </div>
                <StandardButton label={forgotButtonText} working={forgotWorkingButtonText} action={handleForgotPassword}/>
            </Panel>
        </Screen>
    );
}