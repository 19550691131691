import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import {store } from './redux/store'; 
import './App.css';
import { Path } from './common'; 
import { TestPage } from './pages/TestPage';
import { HomePage } from './pages/HomePage';
import { SignInPage } from './pages/SignInPage';
import { SignUpPage } from './pages/SignUpPage';
import { VerifyAccountPage } from './pages/VerifyAccountPage';
import { TransitionPage } from './pages/TransitionPage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { UpdatePasswordPage } from './pages/UpdatePasswordPage';

function App() {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <div style={{ height: `${windowHeight}px`, overflow: 'auto' }}>
          <Routes>
            <Route path={`${Path.TestPage}`} element={<TestPage/>} />
            <Route path={`${Path.HomePage}`} element={<HomePage/>} />
            <Route path={`${Path.SignInPage}`} element={<SignInPage/>} />
            <Route path={`${Path.SignUpPage}`} element={<SignUpPage/>} />
            <Route path={`${Path.VerifyAccountPage}`} element={<VerifyAccountPage/>} />
            <Route path={`${Path.TransitionPage}`} element={<TransitionPage/>} />
            <Route path={`${Path.ForgotPasswordPage}`} element={<ForgotPasswordPage/>} />
            <Route path={`${Path.UpdatePasswordPage}`} element={<UpdatePasswordPage/>} />
              {/* other routes */}
            </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
