import React, { ReactNode, useState, useRef, useEffect, useImperativeHandle, forwardRef, ForwardedRef } from 'react';
import debounce from 'lodash/debounce';
import { Term } from './common';

const inputDebouncePeriod = 300;
const apiDebouncePeriod = 1000;

/************************************************************ SPACER *****************************************/
export interface SpacerProps {
    id?: string,
    size: string,
    axis?: string,
    grow?: boolean,
}

export const Spacer = React.memo((props: SpacerProps) => {
  var className = 'spacer';
  var style: {width?: string, height?: string, flex?: string,} = {};
  if (props.axis === 'x') {style.width = props.size;} else {style.height = props.size;}
  if (props.grow === true) {style.flex = '1 1 auto';}
  if (props.id !== undefined) {className = props.id;};

  return (
    <div className={className} style={style} />
  );
});

/************************************************************ TEXTFIELD *****************************************/
export enum TextInputType {
  text='text',
  username='username',
  password='password',
  name='name',
  email='email',
}
export interface TextFieldProps {
  required?: boolean;
  textInputType?: TextInputType;
  placeholder?: string;
  instr?: string;
  verify?: (password: string) => Promise<{[Term.Status]: boolean, [Term.Message]: string}>,
  // onChange?: (updatedValue: string) => void;
}
export interface InputFieldValue {
  value: string,
  valid: boolean,
  verified: boolean | undefined,
}
export interface InputFieldRef {
  getValue: () => InputFieldValue,
}
interface TextFieldStateObj {
  value: string,
  valid: boolean,
  verified: boolean | undefined,
  instr: string | undefined,
}
export const TextField = React.memo(forwardRef<InputFieldRef, TextFieldProps>((props: TextFieldProps, ref: ForwardedRef<InputFieldRef>) => {
  console.log(`Painting TextField`);
 
  const [firstTime, setFirstTime] = useState(true);
  // const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const [stateObj, setStateObj] = useState<TextFieldStateObj>({value: '', valid: props.required !== true, verified: undefined, instr: props.instr});

  // const [isValid, setIsValid] = useState(props.required !== true);
  // const [value, setValue] = useState('');
  // const [verified, setVerified] = useState(false);

  const valueDebouncerRef = useRef(debounce((value: string) => {
    console.log(`valueDebouncer`);
    if (firstTime) setFirstTime(false);

    const verified = !props.verify ? true : undefined;
    var valid = true;
    if (props.required && (value === undefined || value.length === 0)) {
      valid = false;
    }

    if (valid && props.textInputType !== undefined) {
      valid = validate(value);
    }

    // setErrorMsg(undefined);
    setStateObj({value, valid, verified, instr: props.instr});
  }, inputDebouncePeriod));

  const verifyDebouncerRef = useRef(debounce( async (valueToVerify: string) => {
    console.log(`verifyDebouncer`);
    var valid = validate(valueToVerify);
    if (valid && props.verify) {
      try {
        const verify = await props.verify(valueToVerify);

        if (!verify[Term.Status]) {
          console.log(`Verify error: ${verify.status} ${verify[Term.Message]}`);
          if (verify[Term.Message] !== Term.Error) {
            setStateObj(curState => {
              if (curState.value === valueToVerify) 
                {return {...curState, verified: false, instr: verify[Term.Message]}}
              else
                return curState;
            });
          }
        } else {
          console.log('Verify successful');
          setStateObj(curState => {
            if (curState.value === valueToVerify) 
              {return {...curState, verified: true, instr: verify[Term.Message]}}
            else
              return curState;
          });
        }
      } catch (error) {
        console.log(`Verify error: ${(error instanceof Error ? error.message : 'UNKNOWN')}`);
      }
    }  
  }, apiDebouncePeriod));

  useEffect(() => {
    const currentValueRef = valueDebouncerRef.current;
    const currentVerifyRef = valueDebouncerRef.current;
    return () => {
      currentValueRef.cancel();
      currentVerifyRef.cancel();
    };
  }, []);

  useImperativeHandle(ref, () => ({
    getValue: () => {return {...stateObj}}
  }));

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => { 
    console.log('TextField OnChange');
    valueDebouncerRef.current(event.target.value);
    verifyDebouncerRef.current(event.target.value);
  };

  function validate(value: string): boolean {
    var valid = true;

    switch(props.textInputType) {
      case 'username':
        valid = validateUsername(value);
        break;
      case 'name':
        valid = validateName(value);
        break;
      case 'email':
        valid = validateEmail(value);
        break;
    }
    return valid;
  }

  function validateUsername(username: string): boolean {
    const regex = /^[a-zA-Z][a-zA-Z\d_.]{1,}$/;
    const valid = regex.test(username);
    return valid;
  }

  function validateName(name: string): boolean {
    const regex = /^([a-zA-Z]+)([ a-zA-Z]*)$/;
    const valid = regex.test(name);
    return valid;
  }

  function validateEmail(email: string): boolean {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const valid = regex.test(email);
    return valid;
  }

  function getInstrColor(): string | undefined {
    var color = undefined;
    if (!firstTime && (!stateObj.valid || stateObj.verified === false)) color = 'red';
    if (!firstTime && (stateObj.valid && stateObj.verified === true)) color = 'green';
    return color;
  }
  function getBorder(): string | undefined {
    var border = undefined;
    if (!firstTime && (!stateObj.valid || stateObj.verified === false)) border = 'red solid 1px';
    if (!firstTime && (stateObj.valid && stateObj.verified === true)) border = 'green solid 1px';
    return border;
  }

  return (
    <div className='textField'>

      {props.instr !== undefined ?
        <div className='fieldInstr' style={{color: getInstrColor()}}>
          <span>{stateObj.instr}</span>
        </div> : null
      }

      <div>
          <input
          type={props.textInputType === 'password' ? 'password' : 'text'}
          placeholder={props.placeholder}
          onChange={handleOnChange}
          style={{border: getBorder()}}
          />
      </div>
    </div>
  );
}));

/************************************************************ PASSWORD FIELD *****************************************/
export interface PasswordFieldProps {
  required?: boolean,
  placeholder?: string,
  instr?: string,
}
interface PasswordFieldStateObj {
  value: string,
  valid: boolean,
  visible: boolean,
  instr: string | undefined,
}
export const PasswordField = React.memo(forwardRef<InputFieldRef, PasswordFieldProps>((props: PasswordFieldProps, ref: ForwardedRef<InputFieldRef>) => {

  const [firstTime, setFirstTime] = useState(true);
  const [stateObj, setStateObj] = useState<PasswordFieldStateObj>({value: '', valid: props.required !== true, visible: false, instr: props.instr});
  // const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  // const [isValid, setIsValid] = useState(props.required !== true);
  // const [value, setValue] = useState('');

  const valueDebouncerRef = useRef(debounce((value: string) => {
    if (firstTime) setFirstTime(false);

    var valid = true;
    if (props.required && (value === undefined || value.length === 0)) {
      valid = false;
    }

    if (valid) {valid = validatePassword(value)};

    setStateObj({...stateObj, value, valid, instr: props.instr});
  }, inputDebouncePeriod));

  useImperativeHandle(ref, () => ({
    getValue: () => {return {...stateObj, verified: undefined}}
  }));

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => { 
    valueDebouncerRef.current(event.target.value);
  };

  function validatePassword(password: string): boolean {
    // const regex = /^(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;"'<>,.?\\/~`-|\\\\]).{8,}$/;
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])[^\s]{8,}$/;
    const valid = regex.test(password);
    return valid;
  }

  function getInstrColor(): string | undefined {
    var color = undefined;
    if (!firstTime && !stateObj.valid) color = 'red';
    if (!firstTime && stateObj.valid) color = 'green';
    return color;
  }
  function getBorder(): string | undefined {
    var border = undefined;
    if (!firstTime && !stateObj.valid) border = 'red solid 1px';
    if (!firstTime && stateObj.valid) border = 'green solid 1px';
    return border;
  }

  return (
    <div className='passwordField'>
      
      {props.instr !== undefined ?
        <div className='fieldInstr' style={{color: getInstrColor()}}>
          <span>{props.instr}</span>
        </div> : null
      }

      <div className='passwordFieldContainer'>
        <input
        type={stateObj.visible ? 'text' : 'password'}
        placeholder={props.placeholder}
        onChange={handleOnChange}
        style={{border: getBorder()}}
        />

        <button type='button' className='showPasswordBtn' onClick={(e) => setStateObj({...stateObj, visible: !stateObj.visible})}>
            <img className='showPasswordIcon' style={{display:stateObj.visible ? 'none' : 'inline-block'}} src='/img/eyeslash.png' alt='Password hidden.'/>
            <img className='showPasswordIcon' style={{display:stateObj.visible ? 'inline-block' : 'none'}} src='/img/eye.png' alt='Password visible.'/>
        </button>
      </div>

    </div>
  );
}));

/************************************************************ CHECKBOX *****************************************/
export interface CheckboxProps {
  checked?: boolean,
  label?: string,
}
export interface CheckboxFieldRef {
  getValue: () => boolean | undefined,
}
export const Checkbox = React.memo(forwardRef<CheckboxFieldRef, CheckboxProps>((props: CheckboxProps, ref: ForwardedRef<CheckboxFieldRef>) => {

  const [value, setValue] = useState(props.checked)

  const valueDebounceRef = useRef(debounce((checked: boolean) => { 
    setValue(checked);
    }, inputDebouncePeriod));

  useImperativeHandle(ref, () => ({
    getValue: () => value
  }));
  
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => { 
    valueDebounceRef.current(event.target.checked);
  };

  return (
    <label id='keepMeSignedInDiv'> <input type="checkbox" checked={value} onChange={handleOnChange}/> {props.label}</label>
  );
}));

/************************************************************ STANDARD BUTTON *****************************************/
export interface ButtonProps {
  label: string,
  working?: string,
  disabled?: boolean,
  action: () => Promise<string | undefined>,
}

export const StandardButton = React.memo((props: ButtonProps) => {

  const [error, setError] = useState<undefined | string>(undefined);
  const [working, setWorking] = useState<string | undefined>(undefined);

  const clickDebounceRef = useRef(debounce(async () => {
    if (error) {
      setError(undefined);
      return;
    }

    if (props.action) {
      if (props.working) setWorking(props.working);
      const result = await props.action();
      if (props.working) setWorking(undefined);
      if (result) setError(result);
    }
  }, apiDebouncePeriod));

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    clickDebounceRef.current();
  }

  return (
    <div className='standardButtonContainer'>

      {error ? <div className='errorMsg'><span>{error}</span></div> : null}
      <button className='standardButton' type="button" disabled={props.disabled ? true : undefined} 
        onClick={handleOnClick} onBlur={() => {console.log(`OnBlur: Button ${props.label}`);setError(undefined)}} onFocus={() => console.log(`OnFocus: Button ${props.label}`)}> 
          {working ?? props.label}
      </button>

    </div>
  );
});

/************************************************************ TEXT BUTTON *****************************************/
export const TextButton = React.memo((props: ButtonProps) => {

  const [error, setError] = useState<undefined | string>(undefined);
  const [working, setWorking] = useState<string | undefined>(undefined);

  const clickDebounceRef = useRef(debounce(async () => {
    if (error) {
      setError(undefined);
      return;
    }

    if (props.action) {
      if (props.working) setWorking(props.working);
      const result = await props.action();
      if (props.working) setWorking(undefined);
      if (result) setError(result);
    }
  }, apiDebouncePeriod));

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    clickDebounceRef.current();
  }

  // function getLabel() {
  //   return error ? error : (working ? working : props.label);
  // }
  
  return (
    // <div className='standardButtonContainer'>
      <button className='textButton' type="button" disabled={props.disabled ? true : undefined} onClick={handleOnClick} onBlur={() => setError(undefined)}>
        {error ? error : (working ? working : props.label)}
      </button>
    // </div>
  );
});

/************************************************************ STATIC FOOTER *****************************************/
export interface StaticFooterProps {
  title?: string,
  caption?: string,
}

export const StaticFooter = React.memo((props: StaticFooterProps) => {

  return (
    <div className='staticFooter'>
      <div className='footerTitle'>{props.title}</div>
      <Spacer size='0.5em'/>
      <div className='footerCaption'>{props.caption}</div>
    </div>
  );
});

/************************************************************ PAGE TITLE *****************************************/
export interface PageTitleProps {
  title?: string,
  caption?: string,
}

export const PageTitle = React.memo((props: PageTitleProps) => {

  return (
    <div className='loginTitle'>
      <h2> {props.title}</h2>
    </div>
  );
});

/************************************************************ SCREEN *****************************************/
export interface ScreenProps {
  children?: ReactNode,
}

export const Screen: React.FC<ScreenProps> = React.memo(({children}) => {

  return (
    <div className='screen'>
      {children}
    </div>
  );
});

/************************************************************ PAGE *****************************************/
export interface PageProps {
  children?: ReactNode,
}

export const Page: React.FC<PageProps> = React.memo(({children}) => {

  return (
    <div className='page'>
      {children}
    </div>
  );
});

/************************************************************ BANNER *****************************************/
export interface Tool {
  name: string,
  img: string,
  action: () => void,
}
export interface BannerProps {
  subtitle: string,
  title: string,
  tools: Tool[],
}

export const Banner: React.FC<BannerProps> = React.memo(({subtitle, title, tools}) => {

  return (
    <div className='banner'>
      
      <Column>
        <div className='subtitle'>{subtitle}</div>
        <div className='title'>{title}</div>
      </Column>

      <div id='middle'>
      </div>

      <Column>
        <Spacer size='1.5em'/>
        <Row>
          { tools.map((tool, index) => {
            return (<img className='icon' src={tool.img} alt={tool.name}/>);
          })}
        </Row>
      </Column>

    </div>
  );
});

/************************************************************ COLUMN *****************************************/
export interface ColumnProps {
  children?: ReactNode,
}

export const Column: React.FC<ColumnProps> = React.memo(({children}) => {

  return (
    <div className='column'>
          {children}
    </div>
  );
});

/************************************************************ ROW *****************************************/
export interface RowProps {
  center?: boolean,
  children?: ReactNode,
}

export const Row: React.FC<RowProps> = React.memo(({center, children}) => {

  return (
    <div className='row' style={{justifyContent: center ? 'center' : 'space-between'}}>
          {children}
    </div>
  );
});

/************************************************************ PANEL *****************************************/
export interface PanelProps {
  spacing: string,
  children?: ReactNode,
}

export const Panel: React.FC<PanelProps> = React.memo(({spacing, children}) => {
  const totalChildren = React.Children.count(children);

  console.log(`Painting Panel`);
 
  return (
    <div className='panel'>
    
      {React.Children.map(children, (child, index) => {
         const lastChild = index === (totalChildren - 1);
        return (
          <>
            {child}
            { lastChild ? null : <Spacer size={spacing} grow={true}/>}
          </>
        );
      })}    
      
    </div>
  );
});

/************************************************************ BODY TEXT *****************************************/
export interface BodyTextProps {
  text: string,
  bold?: true,
}

export const BodyText: React.FC<BodyTextProps> = React.memo(({text, bold}) => {
  console.log(`Painting BodyText`);
 
  return (
    <div className='bodyText' style={{fontWeight: bold ? 700 : undefined}}>
      {text}  
    </div>
  );
});

