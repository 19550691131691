import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { BodyText, TextField, StandardButton, TextInputType, PageTitle, Panel, InputFieldRef, Screen, PasswordField } from '../components';
import { Path, QueryString, Term, AuthRequest, callApi, ResponseCode, ApiResponse } from '../common'

const usernamePrompt = 'USERNAME';
const usernameInstrText = 'Enter the username for the account you would like to complete setup for.';

const passwordPrompt = 'PASSWORD';
const passwordInstrText = 'Password: Length (8 or more ). No spaces permitted. Contains at least one each of uppercase letter, lowercase letter, symbol, and number.';

const verifyPasswordPrompt = 'VERIFY PASSWORD';
const verifyPasswordInstrText = 'Verify Password: Must match the password you have chosen.';

const verifyCodeTitle = 'Verify Your Account';
const codePrompt = 'VERIFICATION CODE';
const codeInstrText = 'Verification Code: Please enter the code sent to your email.';
const newAccountHelpText = 'To verify your account, we sent a code to the email address you provided as part of creating your new account. You will need to input that code to activate your account.'

// const resendCodeButtonText = "Resend Code";
const verifyCodeButtonText = 'Verify Code';

// const stateResendingCode = 'Resending verification code...';
const stateVerifyingCode = 'Verifying code...';

const errPasswordMismatch = 'Does not match the password above.';
const passwordMatchText = 'Both password and verify password match.';
const choosePasswordHelpText = 'Please choose a password for your account.'

const errInput = 'Please enter a valid verification code and try again.';
// const errResendingCode = 'An error occurred while resending the code. Please try again after some time.';
const errVerifyingCode = 'The code entered does not match the code sent to your email address or has expired. Please correct and try again.';

// const errUserDoesNotExist = 'The username you provided is not associated with any account.';
const errAuthFailed = 'The code you provided is invalid. Please update and try again.';
const errAuthFailedAlt = 'The username or code you provided is invalid. Please update and try again.';

const welcomePageTitle = 'Congratulations !!';
const welcomeMessage = 'Your account is now active! Sign in to start using MyVote.info.';
const welcomeAction = 'Proceed to Sign In';

// const numAllowedResends = 3;

export function VerifyAccountPage() {
    const location = useLocation();
    const username = location.state ? location.state[Term.Data] : undefined;
    console.log(`Verify Code Page for: ${username}`);

    const navigate = useNavigate();

    const codeRef = useRef<InputFieldRef>(null);
    const usernameRef = useRef<InputFieldRef>(null);
    const passwordRef = useRef<InputFieldRef>(null);
    const verifyPasswordRef = useRef<InputFieldRef>(null);

    // const [resendCount, setResendCount] = useState(0);
    // const [state, setState] = useState('');

    const queryString = window.location.search; 

    const authVerifyCode = async (user: string, code: string, password: string): Promise<ApiResponse> => {
        console.log(`authVerifyCode`);

        // cmd=verifyCode&username=<value>&email=<value>
        const query = new QueryString().add(Term.Request, AuthRequest.VerifyCode).value();
        const data = {[Term.Username]: user, [Term.Code]: code, [Term.Password]: password};
        const response = await callApi(Path.AuthApi, query, data);
        return response;
    }

    // const authResendCode = async (user: string): Promise<ApiResponse> => {
    //     console.log(`authResendCode`);

    //     // cmd=sendCode&username=<value>&context=<value>
    //     const query = new QueryString().add(Term.Request, AuthRequest.ResendCode).value();
    //     const data = {[Term.Username]: user};
    //     const response = await callApi(Path.AuthApi, query, data);
    //     return response;
    // }

    const verifyPassword = async (value: string): Promise<{[Term.Status]: boolean, [Term.Message]: string}> => {
        const password = passwordRef.current ? (passwordRef.current.getValue().valid ? passwordRef.current.getValue().value : undefined) : undefined;
        if (value !== password) return {[Term.Status]: false, [Term.Message]: errPasswordMismatch};
        else return {[Term.Status]: true, [Term.Message]: passwordMatchText};
    };

    // const handleResendCode = async (): Promise<string | undefined> => {
    //     var message = errResendingCode;
    //     console.log(`Sending verification code..`);
    //     const usernameValue = username ? username : (usernameRef.current ? (usernameRef.current.getValue().valid ? usernameRef.current.getValue().value : undefined) : undefined);

    //     if (!usernameValue) return errInput;
    //     const response = await authResendCode(usernameValue);
    //     const status = response[Term.Status];
    //     if (status === ResponseCode.Success) { setResendCount(resendCount+1); return undefined;}
    //     else {
    //         switch(status) {
    //             case ResponseCode.UserDoesNotExist:
    //                 message = errUserDoesNotExist;
    //                 break;
    //         }
    //     }
    //     return message;
    // }

    const handleVerifyCode = async (): Promise<string | undefined> => {
        
        console.log(`Verifying code..`);
        let code = codeRef.current ? (codeRef.current.getValue().valid ? codeRef.current.getValue().value : undefined) : undefined;
        let usernameValue = username ? username : (usernameRef.current ? (usernameRef.current.getValue().valid ? usernameRef.current.getValue().value : undefined) : undefined);
        const password = passwordRef.current ? (passwordRef.current.getValue().valid ? passwordRef.current.getValue().value : undefined) : undefined;
        
        if (!code || !usernameValue || !password) return errInput;

        code = code.trim();
        usernameValue = usernameValue.trim().toLowerCase();
        const response = await authVerifyCode(usernameValue, code, password);
        const status = response[Term.Status];
        if (status === ResponseCode.Success) { 
            const stateObj = {[Term.Title]: welcomePageTitle, [Term.Message]: welcomeMessage, [Term.Action]: welcomeAction, [Term.Destination]: `${Path.SignInPage}`};
            navigate(`${Path.TransitionPage}${queryString}`, {state: stateObj});

            // const stateObj = {[Term.Data]: username, [Term.Context]: Term.NewAccount};
            // navigate(`${Path.WelcomePage}${queryString}`, {state: stateObj});
            return undefined;
        } else {
            var message = errVerifyingCode;
            switch(status) {
                case ResponseCode.AuthFailed:
                    message = username ? errAuthFailed : errAuthFailedAlt;
                    break;
            }
            return message;
        }        
    }

    // function getResendButtonLabel(): string {
    //     return `${resendCodeButtonText} (${resendCount} of ${numAllowedResends})`; 
    // }

    return (
        <Screen>
            <Panel spacing='3em'>
                <PageTitle title={verifyCodeTitle}/>
                <div>
                    { !username ?
                        <TextField ref={usernameRef} required={true} textInputType={TextInputType.username} placeholder={usernamePrompt} instr={usernameInstrText}/>
                        : null
                    }
                    <TextField ref={codeRef} required={true} textInputType={TextInputType.text} placeholder={codePrompt} instr={codeInstrText}/>
                    <BodyText text={newAccountHelpText}/>
                </div>
                <div>
                    <PasswordField ref={passwordRef} required={true} placeholder={passwordPrompt} instr={passwordInstrText}/>
                    <TextField ref={verifyPasswordRef} required={true} textInputType={TextInputType.password} placeholder={verifyPasswordPrompt} instr={verifyPasswordInstrText} verify={verifyPassword}/>
                    <BodyText text={choosePasswordHelpText}/>
                </div>
                {/* {state === stateResendingCode ? <BodyText text={stateResendingCode}/> : 
                    <TextButton disabled={resendCount >=3 ? true : false} label={getResendButtonLabel()} action={handleResendCode} />} */}
                
                <StandardButton label={verifyCodeButtonText} working={stateVerifyingCode} action={handleVerifyCode}/>

            </Panel>
        </Screen>
    );
}