import React, { useRef } from 'react';
// import axios from 'axios';
import { StandardButton, TextField, TextInputType, InputFieldRef, PageTitle, Panel, Screen, Row, TextButton, Spacer } from '../components';
import { useNavigate } from 'react-router-dom';
import { ApiResponse, AuthRequest, Path, QueryString, ResponseCode, Term, callApi } from '../common'

const signUpTitle = 'Create a new account';

const usernamePrompt = 'USERNAME';
const usernameInstrText = `Username: Length (2 or more). Case insensitive, starts with a letter. No symbols except period (.) and underscore (_).`;

const fullnamePrompt = 'YOUR NAME';
const fullnameInstrText = 'Your Name: Enter your first and last name.';

const emailPrompt = 'EMAIL';
const emailInstrText = 'Email: Enter a valid email address (will be verified by sending a code to this email address.';

const createAccountButtonText = 'Create Account';
const createAccountWorkingText = 'Creating new account...';

const errInput = 'One or more information above is not filled correctly.';

const errUsernameNotAvailable = 'The username is not available. If you registered it previously, you can sign in or complete account setup by using the options below.';
const usernameAvailableText = 'Congratulations, the username is available to use.';

const errEmailNotAvailable = 'The email address is already is use with another account';
const emailAvailableText = 'Congratulations, the email address is available to use.';

const errUnableToCreateAccount = 'Sorry, an error occurred creating the account. Please try again after some time.';
const errUserExists = 'Sorry, the username is already in use by another account. Please choose another and try again.';
const errVerifyAccount = 'Sorry, the username is already in use. If you registered it previously, you can sign in or complete account setup by verifying the account.';

const haveAccountText = 'Already Have Account? Sign In.';
const completeSetupText = 'Verify Previously Setup Account';

const regularSpacing = '1em';

export function SignUpPage() {
    console.log(`painting SignUpPage`);

    const navigate = useNavigate();

    const usernameRef = useRef<InputFieldRef>(null);
    const nameRef = useRef<InputFieldRef>(null);
    const emailRef = useRef<InputFieldRef>(null);

    const queryString = window.location.search; 
    
    const authFindUser = async (username: string | undefined, email: string |undefined): Promise<ApiResponse> => {
        console.log(`authFindUser`);

        //cmd=findUser&username=<value>&email=<value>
        const query = new QueryString().add(Term.Request, AuthRequest.FindUser).add(Term.Username, username).add(Term.Email, email).value();
        const data = undefined;
        const response = await callApi(Path.AuthApi, query, data);

        return response;
    }

    const authCreateAccount = async (username: string, name: string, email: string): Promise<ApiResponse> => {
        console.log(`authCreateAccount`);

        //cmd=createAccount
        const query = new QueryString().add(Term.Request, AuthRequest.SignUp).value();
        let data: {[key in Term]? : string} = {
            [Term.Username]: username,
            [Term.Name]: name,
            [Term.Email]: email,
        };
        const response = await callApi(Path.AuthApi, query, data);
        return response;
    }

    const verifyUsername = async (value: string): Promise<{[Term.Status]: boolean, message: string}> => {
        console.log(`verifyUsername`);
        var status = true;
        var message = usernameAvailableText;

        const response = await authFindUser(value, undefined);
        if (response[Term.Status] !== ResponseCode.Success) return {[Term.Status]: false, [Term.Message]: Term.Error};
        const foundUsers = response[Term.Result] ? (response[Term.Result] as object[]).length > 0 : false;  
        if (foundUsers) { status = false; message = errUsernameNotAvailable};

        return ({[Term.Status]: status, [Term.Message]: message});
    }

    const verifyEmail = async (value: string): Promise<{[Term.Status]: boolean, [Term.Message]: string}> => {
        console.log(`verifyEmail`);
        var status = true;
        var message = emailAvailableText;

        const response = await authFindUser(undefined, value);
        if (response[Term.Status] !== ResponseCode.Success) return {[Term.Status]: false, [Term.Message]: Term.Error};
        const foundUsers = response[Term.Result] ? (response[Term.Result] as object[]).length > 0 : false;  
        if (foundUsers) { status = false; message = errEmailNotAvailable};

        return ({[Term.Status]: status, [Term.Message]: message});
    }

    const handleHaveAccount = async (): Promise<string | undefined> => {
        navigate(`${Path.SignInPage}${queryString}`);
        return undefined;
    }

    const handleCompleteSetup = async (): Promise<string | undefined> => {
        navigate(`${Path.VerifyAccountPage}${queryString}`);
        return undefined;
    }

    const handleCreateNewAccount = async (): Promise<string | undefined> => {
        let username = usernameRef.current ? (usernameRef.current.getValue().valid && usernameRef.current.getValue().verified ? usernameRef.current.getValue().value : undefined) : undefined;
        let name = nameRef.current ? (nameRef.current.getValue().valid ? nameRef.current.getValue().value : undefined) : undefined;
        let email = emailRef.current ? (emailRef.current.getValue().valid  && emailRef.current.getValue().verified ? emailRef.current.getValue().value : undefined) : undefined;

        if (username === undefined || name === undefined || email === undefined) {
            return errInput;
        }

        username = username.trim().toLowerCase();
        name = name.trim();
        email = email.trim().toLowerCase();
        
        console.log(`Create new account: ${username} ${name} ${email}`);
        const response = await authCreateAccount(username, name, email);
        const status = response[Term.Status];

        if (status === ResponseCode.Success) {
            const stateObj: {[key in Term]? : string} = {
                [Term.Data]: username,
            };
            navigate(`${Path.VerifyAccountPage}${queryString}`, {state: stateObj});
            return undefined;
        } else {
            var message = errUnableToCreateAccount;
            switch(status) {
                case ResponseCode.VerifyAccount:
                    message = errVerifyAccount;
                    break;
                case ResponseCode.UserExists:
                    message = errUserExists;
                    break;
                case ResponseCode.InvalidPassword:
                    break;
            }
            return message;            
        }
    }

    return (
        <Screen>
            <Panel spacing='1em'>
                <PageTitle title={signUpTitle}/>
                <div>
                    <TextField ref={usernameRef} required={true} textInputType={TextInputType.username} placeholder={usernamePrompt} instr={usernameInstrText} verify={verifyUsername}/>
                    <TextField ref={nameRef} required={true} textInputType={TextInputType.name} placeholder={fullnamePrompt} instr={fullnameInstrText}/>
                    <TextField ref={emailRef} required={true} textInputType={TextInputType.email} placeholder={emailPrompt} instr={emailInstrText} verify={verifyEmail}/>
                </div>
                <Row center={true}><TextButton label={haveAccountText} action={handleHaveAccount}/></Row>
                <div>
                    <Row center={true}><TextButton label={completeSetupText} action={handleCompleteSetup}/></Row>
                    <Spacer size={regularSpacing}/>
                    <StandardButton label={createAccountButtonText} working={createAccountWorkingText} action={handleCreateNewAccount} />
                </div>
            </Panel>
        </Screen>
    );
}
