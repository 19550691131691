import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { BodyText, StandardButton, PageTitle, Panel, Screen } from '../components';
import { Path, Term } from '../common'

export function TransitionPage() {
    const location = useLocation();
    const pageTitle = location.state ? location.state[Term.Title] : undefined;
    const pageMessage = location.state ? location.state[Term.Message] : undefined;
    const pageActionText = location.state ? location.state[Term.Action] : undefined;
    const pageActionDestination = location.state ? location.state[Term.Destination] : undefined;
    const forwardStateObj = location.state ? location.state[Term.State] : undefined;

    console.log(`Transition page`);

    const navigate = useNavigate();
    if (!pageTitle || !pageMessage || !pageActionText || !pageActionDestination ) {
        navigate(`${Path.HomePage}`);
        return (
            <div/>
        );
    }

    const queryString = window.location.search; 

    const handleTransition = async (): Promise<string | undefined> => {
        console.log(`Transitioning to destination page..`);
        navigate(`${pageActionDestination}${queryString}`, {state: forwardStateObj});
        return undefined;
    }

    return (
        <Screen>
            <Panel spacing='3em'>
                <PageTitle title={pageTitle}/>
                <div>
                    <BodyText text={pageMessage} bold={true}/>
                </div>
                
                <StandardButton label={pageActionText} action={handleTransition}/>

            </Panel>
        </Screen>
    );
}