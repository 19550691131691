import { createSlice } from '@reduxjs/toolkit';
import { AppStateValue, User } from '../common';

export interface AppState {
  appState: AppStateValue,
  user: User | undefined,
}
const initialAppState: AppState = {
  appState: AppStateValue.None,
  user: undefined,
}

const appSlice = createSlice({
  name: 'App',
  initialState: initialAppState,
  reducers: {
    sessionActivated: (state, action) => {
      console.log(`Reducer: sessionActivated ${JSON.stringify(action.payload)}`); 
      state.user = action.payload; 
      state.appState = AppStateValue.SignedIn;
    },
  }
});

export const { sessionActivated } = appSlice.actions;
export const appReducer = appSlice.reducer;
