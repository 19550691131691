import axios, {AxiosError} from 'axios';
import { NavigateFunction } from 'react-router-dom';

export enum Path {
    // client side paths
    TestPage = '/test',
    HomePage = '/',
    SignInPage = '/app/sign-in',
    SignUpPage = '/app/sign-up',
    ForgotPasswordPage = '/app/forgot-password',
    UpdatePasswordPage = '/app/update-password',
    VerifyAccountPage = '/app/verify-account',
    WelcomePage = '/app/account-active',
    TransitionPage = '/app/next',

    // if you change any paths below, the one additional place you need to
    // update them is in cdl/cdk.json under contexts item.
    HomeApi = '/api/home',
    AuthApi = '/auth',
}

export enum AppStateValue {
    None = 'none',
    SignedIn = 'signedIn',
    SignedOut = 'signedOut',
}

export enum Cookies {
    activityCookie = 'myVoteApp-00',
}

export interface OutOfBandData {
    user: User
}

export interface User {
    username: string | undefined,
    email: string | undefined,
    name: string | undefined,
    roles: string | undefined,
}

export interface ActivityCookie {
    s: number,
    l: number, 
    n: number,
    a: number,
    r: number,
    k: boolean,
    v: string,
}

export enum Constants {
    interactivePeriod = 15 * 60,
    activePeriod = 24 * 60 * 60,
    sessionLimit = 2 * 24 * 60 * 60,
    sessionOverageLimit = 3 * 24 * 60 * 60,
    advanceRefreshPeriod = 5 * 60, 
}

export enum Term {
    Status = 'status',
    Error = 'error',
    Result = 'result',
    Request = 'request',
    Query = 'query',
    Data = 'data',
    Code = 'code',
    Context = 'context',
    Username = 'username',
    Password = 'password',
    Name = 'name',
    Email = 'email',
    NewAccount = 'newAccount',
    ForgotPassword = 'forgotPassword',
    KeepMeSignedIn = 'keepMeSignedIn',
    Scope = 'scope',
    Global = 'global',
    Local = 'local',
    Title = 'title',
    Message = 'message',
    Action = 'action',
    Destination = 'destination',
    State = 'state',
    Session = 'session',
    OutOfBandData = 'outOfBandData',
    User = 'user',
    UserNumber = 'userNumber',
}

export enum AuthRequest {
    SignUp = 'signUp',
    SignIn = 'signIn',
    Renew = 'renew',
    SignOut = 'signOut',
    ResendCode = 'resendCode',
    VerifyCode = 'verifyCode',
    FindUser = 'findUser',
    ForgotPassword = 'forgotPassword',
    UpdatePassword = 'updatePassword',
}

export enum ResponseCode {
    Success = 'Success',
    Failed = 'Failed',
    Forbidden = 'forbidden',
    AuthFailed = 'AuthFailed',
    UserExists = 'UserExists',
    UserDoesNotExist = 'UserDoesNotExist',
    EmailExists = 'EmailExists',
    VerifyAccount = "VerifyAccount",
    InvalidPassword = "InvalidPassword",
    PasswordResetRequired = "PasswordResetRequired",
    CodeMismatch = "CodeMismatch",
    CodeExpired = "CodeExpired",
}

export class QueryString {
    private queryString: string = '';

    constructor() {
        this.queryString = window.location.search;
    }

    add(param: string | undefined, value: string | undefined): QueryString {
        if (param === undefined || value === undefined) return this;
        const escapedParam = encodeURIComponent(param);
        const escapedValue = encodeURIComponent(value);
        const startChar = this.queryString === '' ? '?' : '&';
        var part = `${startChar}${escapedParam}=${escapedValue}`;
        this.queryString += part;
        return this;
    }

    value(): string {
        return this.queryString;
    }
}

export interface ApiResponse {
    [Term.Status]: ResponseCode,
    [Term.Result]: object,
}

export const callApi = async (service: string, query: string, data: object | undefined): Promise<ApiResponse> => {
    console.log(`callApi`);

    axios.defaults.withCredentials = true;
    // axios.defaults.xsrfCookieName = 'XSRF-TOKEN';    

    const baseUrl = window.location.origin;
    var result = undefined;

    console.log(`CallApi Request: ${service} ${query} ${JSON.stringify(data)}`);
    try {
        var response;
        if (data === undefined) {
            response = await axios.get(`${baseUrl}${service}${query}`);
        } else {
            response = await axios.post(`${baseUrl}${service}${query}`, data);
        }
        result = response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        console.log(`Error callApi: ${service} ${query} ${JSON.stringify(data)}: ${axiosError.message}`);
        if (axiosError.response && axiosError.response.status === 401) {
            result = {[Term.Status]: ResponseCode.AuthFailed, [Term.Result]: axiosError};
        } else if (axiosError.response && axiosError.response.status === 403) {
            result = {[Term.Status]: ResponseCode.Forbidden, [Term.Result]: axiosError};
        } else {
            result = {[Term.Status]: ResponseCode.Failed, [Term.Result]: axiosError};
        }
    }

    console.log(`CallApi Result: ${JSON.stringify(result)}`);
    return (result);
}

export function getCookies(): {[key: string]: string} {
    let obj: {[key: string]: string} = {};
    
    const cookies = document.cookie.split(';');
    if (!cookies) return obj;

    for (const cookie of cookies) {
        const index = cookie.indexOf('=');
        const name = cookie.substring(0, index).trim();
        const value = cookie.substring(index + 1);
        obj[name] = value;
    }
    return obj;
}

export async function computeUrlSafeHash(input: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = new Uint8Array(hashBuffer);

    // Convert the bytes to a hexadecimal string
    const hexString = Array.from(hashArray)
                            .map(byte => byte.toString(16).padStart(2, '0'))
                            .join('');
    console.log(`${input}: Hash(${hexString})`);
    return hexString;

    // Convert bytes to standard Base64
    // const base64String = btoa(String.fromCharCode(...hashArray));
    // Convert to URL-safe Base64
    // const urlSafeBase64 = base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    // return urlSafeBase64;
}

export async function renewAccess(navigate: NavigateFunction): Promise<string | undefined>  {
    console.log(`Assessing access renewal.`);
    const queryString = window.location.search; 

    let activityCookie: ActivityCookie | undefined = undefined;
    try {
        const cookies = getCookies();
        if (!Cookies.activityCookie) {
            navigate(`${Path.SignInPage}${queryString}`);
            return;
        }
        
        activityCookie = JSON.parse(cookies[Cookies.activityCookie]);
    } catch (error) {
        console.log(`Error retrieving activity cookie: ${error}`);
        navigate(`${Path.SignInPage}${queryString}`);
        return;
    }

    let userNumber = activityCookie!.n;
    let start = activityCookie!.s;
    const lastActivity = activityCookie!.l;
    const accessTokenExpiry = activityCookie!.a;
    const refreshTokenExpiry = activityCookie!.r;
    const keepMeSignedIn = activityCookie!.k;
    console.log(`User: ${userNumber} Start: ${start} Last: ${lastActivity} Refresh: ${refreshTokenExpiry} KeepSignedIn: ${keepMeSignedIn}`);

    const now = Math.floor(Date.now() / 1000);
    const interactive = (now - lastActivity) < Constants.interactivePeriod ? true : false;
    const active = (now - lastActivity) < Constants.activePeriod ? true : false;
    const accessExpired = accessTokenExpiry <= now;
    const refreshExpired = refreshTokenExpiry <= now;
    const sessionExpired = (now - start) > Constants.sessionLimit ? true : false;
    const sessionOverageExpired = (now - start) > Constants.sessionOverageLimit ? true : false;
    const advanceRefresh = !accessExpired && (accessTokenExpiry - now) <= Constants.advanceRefreshPeriod ? true : false;
    console.log(`Interactive: ${interactive} Active: ${active} SessionExpired: ${sessionExpired} SessionOverageExpired: ${sessionOverageExpired}`);

    // sign in if refresh token expired
    if (refreshExpired) {
        console.log(`Refresh token expired`);
        navigate(`${Path.SignInPage}${queryString}`);
        return;
    }

    if (!accessExpired && !advanceRefresh) {
        console.log(`Access not expired.`);
        const sessionId = await computeUrlSafeHash(`${userNumber}-${start}`);
        return sessionId;
    }

    const extendInteractiveSession = accessExpired && interactive && !sessionOverageExpired;
    const extendActiveSession = accessExpired && active && !sessionExpired;
    console.log(`Advance Refresh: ${advanceRefresh} KeepMeSignedIn: ${keepMeSignedIn} Extend Interactive: ${extendInteractiveSession} Extend Active: ${extendActiveSession}`);
    if (advanceRefresh || keepMeSignedIn || (accessExpired && ((interactive && !sessionOverageExpired) || (active && !sessionExpired)))) {
        console.log(`Renewing access for: ${userNumber}`);
        const response = await authRenew(userNumber);
        if (response[Term.Status] !== ResponseCode.Success) {
            console.log(`Renew Failed: ${response[Term.Result]}`);
            navigate(`${Path.SignInPage}${queryString}`);
            return;
        }
        // since we have all new cookies, we need to recompute the session number
        try {
            const cookies = getCookies();
            if (!Cookies.activityCookie) {
                navigate(`${Path.SignInPage}${queryString}`);
                return;
            }
            
            activityCookie = JSON.parse(cookies[Cookies.activityCookie]);
        } catch (error) {
            console.log(`Error retrieving activity cookie: ${error}`);
            navigate(`${Path.SignInPage}${queryString}`);
            return;
        }
        userNumber = activityCookie!.n;
        start = activityCookie!.s;
        return await computeUrlSafeHash(`${userNumber}-${start}`);
    }
}

const authRenew = async (userNumber: number): Promise<ApiResponse> => {
    const query = new QueryString().add(Term.Request, AuthRequest.Renew).value();
    const data = {[Term.UserNumber]: userNumber};
    const response = await callApi(Path.AuthApi, query, data);
    return response;
}
