import { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { BodyText, TextField, PasswordField, StandardButton, TextButton, TextInputType, PageTitle, Panel, InputFieldRef, Screen } from '../components';
import { Path, QueryString, Term, AuthRequest, callApi, ResponseCode, ApiResponse } from '../common'

const updatePasswordTitle = 'Update Password';
const codePrompt = 'VERIFICATION CODE';
const codeInstrText = 'Verification Code: Please enter the code sent to your email.';
const updatePasswordHelpText = 'To recover your account, we sent a verification code to your registered email address. You will need to input that code to recover your account.'

const passwordPrompt = 'PASSWORD';
const passwordInstrText = 'Password: At least 6 characters with one symbol, one number, no spaces.';

const verifyPasswordPrompt = 'VERIFY PASSWORD';
const verifyPasswordInstrText = 'Verify Password: Must match the password you have chosen.';

const errPasswordMismatch = 'Does not match the password above.';
const passwordMatchText = 'Both password and verify password match.';

const resendCodeButtonText = "Resend Code";
const updatePasswordButtonText = 'Update Password';

const stateResendingCode = 'Resending verification code...';
const stateVerifyingCode = 'Verifying code...';

const errInput = 'Please enter a valid verification code and password before try again.';
const errResendingCode = 'An error occurred while updating the password. Please try again after some time.';
const errUpdatingPassword = 'The code entered does not match the code sent to your email address or has expired. Please correct and try again.';

const errCodeMismatch = 'The code you entered does not match the code sent to your email.';
const errUserDoesNotExist = 'The username you provided is not associated with any account.';
const errCodeExpired = 'The code you provided has expired. Please request a new verification code above and re-enter the updated code.';
const errVerifyAccount = 'The account associated with the username has not yet been verified. You can verify it by going to Create New Account on the home page.';

const passwordUpdatedTitle = 'Congratulations !!';
const passwordUpdatedMessage = 'Your password has been updated! Sign in to start using MyVote.info.';
const passwordUpdatedAction = 'Proceed to Sign In';

const numAllowedResends = 3;

export function UpdatePasswordPage() {
    const location = useLocation();
    const username = location.state ? location.state[Term.Data] : undefined;
    console.log(`Update Password Page for: ${username}`);

    const navigate = useNavigate();

    const codeRef = useRef<InputFieldRef>(null);
    const passwordRef = useRef<InputFieldRef>(null);
    const verifyPasswordRef = useRef<InputFieldRef>(null);

    const [resendCount, setResendCount] = useState(0);
    const [state, setState] = useState('');

    const queryString = window.location.search; 
    // const baseUrl = window.location.origin;

    const authUpdatePassword = async (newPassword: string, code: string): Promise<ApiResponse> => {
        console.log(`authVerifyCode`);

        // cmd=verifyCode&username=<value>&email=<value>
        const query = new QueryString().add(Term.Request, AuthRequest.UpdatePassword).value();
        const data = {[Term.Username]: username, [Term.Code]: code};
        const response = await callApi(Path.AuthApi, query, data);
        return response;
    }

    const authResendCode = async (): Promise<ApiResponse> => {
        console.log(`authResendCode`);

        // cmd=sendCode&username=<value>&context=<value>
        const query = new QueryString().add(Term.Request, AuthRequest.ForgotPassword).value();
        const data = {[Term.Username]: username};
        const response = await callApi(Path.AuthApi, query, data);

        return response;
    }

    const verifyPassword = async (value: string): Promise<{[Term.Status]: boolean, [Term.Message]: string}> => {
        const password = passwordRef.current ? (passwordRef.current.getValue().valid ? passwordRef.current.getValue().value : undefined) : undefined;
        if (value !== password) return {[Term.Status]: false, [Term.Message]: errPasswordMismatch};
        else return {[Term.Status]: true, [Term.Message]: passwordMatchText};
    };

    const handleResendCode = async (): Promise<string | undefined> => {
        var message = errResendingCode;
        console.log(`Resending verification code..`);

        setState(stateResendingCode);
        const response = await authResendCode();
        const status = response[Term.Status];
        if (status === ResponseCode.Success) { setResendCount(resendCount+1); return undefined;}
        else {
            switch(status) {
                case ResponseCode.UserDoesNotExist:
                    message = errUserDoesNotExist;
                    break;
            }
        }
        setState('');
        return message;
    }

    const handleUpdatePassword = async (): Promise<string | undefined> => {
        var message = errUpdatingPassword;
        console.log(`Updating password..`);
        let code = codeRef.current ? (codeRef.current.getValue().valid ? codeRef.current.getValue().value : undefined) : undefined;
        const password = passwordRef.current ? (passwordRef.current.getValue().valid ? passwordRef.current.getValue().value : undefined) : undefined;
        
        if (!code || !password) return errInput;

        code = code.trim();
        const response = await authUpdatePassword(password, code);
        const status = response[Term.Status];
        if (status === ResponseCode.Success) { 
            const stateObj = {[Term.Title]: passwordUpdatedTitle, [Term.Message]: passwordUpdatedMessage, [Term.Action]: passwordUpdatedAction, [Term.Destination]: `${Path.SignInPage}`};
            navigate(`${Path.TransitionPage}${queryString}`, {state: stateObj});

            // const stateObj = {[Term.Data]: username, [Term.Context]: Term.ForgotPassword};
            // navigate(`${Path.WelcomePage}${queryString}`, {state: stateObj});
            return undefined;
        } else {
            switch(status) {
                case ResponseCode.CodeExpired:
                    message = errCodeExpired;
                    break;
                case ResponseCode.CodeMismatch:
                    message = errCodeMismatch;
                    break;
                    case ResponseCode.VerifyAccount:
                    message = errVerifyAccount;
                    break;
                case ResponseCode.UserDoesNotExist:
                    message = errUserDoesNotExist;
                    break;
            }
        }
        return message;
    }

    function getResendButtonLabel(): string {
        return `${resendCodeButtonText} (${resendCount} of ${numAllowedResends})`; 
    }

    if (!username) return (<div/>);
    return (
        <Screen>
            <Panel spacing='3em'>
                <PageTitle title={updatePasswordTitle}/>
                <div>
                    <TextField ref={codeRef} required={true} textInputType={TextInputType.text} placeholder={codePrompt} instr={codeInstrText}/>
                    <PasswordField ref={passwordRef} required={true} placeholder={passwordPrompt} instr={passwordInstrText}/>
                    <TextField ref={verifyPasswordRef} required={true} textInputType={TextInputType.password} placeholder={verifyPasswordPrompt} instr={verifyPasswordInstrText} verify={verifyPassword}/>
                    <BodyText text={updatePasswordHelpText}/>
                </div>
                {state === stateResendingCode ? <BodyText text={stateResendingCode}/> : 
                    <TextButton disabled={resendCount >=3 ? true : false} label={getResendButtonLabel()} action={handleResendCode} />}
                
                <StandardButton label={updatePasswordButtonText} working={stateVerifyingCode} action={handleUpdatePassword}/>

            </Panel>
        </Screen>
    );
}