import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spacer, TextField, PasswordField, StandardButton, TextButton, TextInputType, StaticFooter, PageTitle, Panel, 
    InputFieldRef, Checkbox, CheckboxFieldRef, Screen, Row, Column } from '../components';
import { ApiResponse, AuthRequest, Path, QueryString, ResponseCode, Term, callApi } from '../common'
import { useDispatch } from 'react-redux';
import { sessionActivated } from '../redux/reducers';

const teaserItemBreakpoint = 35;//em

const titleText = 'Let your voice be heard';
const footerText = 'MyVote.info';
const footerCaption = 'Shape Your Future';
const usernamePrompt = 'USERNAME';
const passwordPrompt = 'PASSWORD';
const signUpButtonText = 'Create New Account';
const signInButtonText = 'Login';
const keepMeSignedInText = 'keep me signed in';
const forgotPasswordText = 'Forgot password?';

const errInput = 'Either username or password is blank or invalid.';
const errSigningIn = 'Incorrect username or password. Please correct and try again.';

const verifyPageTitle = 'Verify Account';
const verifyMessage = 'This account has not yet been setup completely. It needs to be verified before it can be used.';
const verifyAction = 'Verify Account';

const stateSigningIn = 'Signing you in...';
/**
 * -- top margin --
 * SignIn Title
 * -- top spacing --
 * [ MyVoice ]
 * [ -- top spacing -- ]
 * SignIn block
 * -- bottom spacing --
 * MyVote.info block
 * -- bottom margin --
 */

const smallSpacing = '0.5em';//0.5 em, spacing between username/password/register/login elements

export function SignInPage() {
    console.log(`painting SignInPage`);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const teaserContentRef = useRef<ReactNode>(null);
    const usernameRef = useRef<InputFieldRef>(null);
    const passwordRef = useRef<InputFieldRef>(null);
    const keepMeSignedInRef = useRef<CheckboxFieldRef>(null);

    const queryString = window.location.search; 

    const authSignIn = async (username: string, password: string, keepMeSignedIn: boolean): Promise<ApiResponse> => {
        const query = new QueryString().add(Term.Request, AuthRequest.SignIn).value();
        const data = {[Term.Username]: username, [Term.Password]: password, [Term.KeepMeSignedIn]: keepMeSignedIn};
        const response = await callApi(Path.AuthApi, query, data);
        return response;
    }

    const handleSignIn = async (): Promise<string | undefined> => {
        // Implement sign-in logic here
        let username = usernameRef.current ? (usernameRef.current.getValue().valid ? usernameRef.current.getValue().value : undefined) : undefined;
        const password = passwordRef.current ? (passwordRef.current.getValue().valid ? passwordRef.current.getValue().value : undefined) : undefined;
        const keepMeSignedIn = keepMeSignedInRef.current ? keepMeSignedInRef.current.getValue() : true;

        if (username === undefined || password === undefined) {
            return errInput;
        }

        username = username.trim().toLowerCase();
        console.log(`Signing in with: ${username} ${password} ${keepMeSignedIn}`);
        const response = await authSignIn(username, password, keepMeSignedIn ?? false);
        const status = response[Term.Status];
        if (status === ResponseCode.Success) {
            dispatch(sessionActivated(response[Term.Result]));
            navigate(`${Path.HomePage}${queryString}`);
            return undefined;
        } else {
            var message: string | undefined = errSigningIn;
            switch(status) {
                case ResponseCode.VerifyAccount:
                    const stateObj = {[Term.Title]: verifyPageTitle, [Term.Message]: verifyMessage, 
                        [Term.Action]: verifyAction, [Term.Destination]: `${Path.VerifyAccountPage}`,
                        [Term.State]: {[Term.Data]: username}};
                    navigate(`${Path.TransitionPage}${queryString}`, {state: stateObj});
                    message = undefined;
                    break;
                case ResponseCode.PasswordResetRequired:
                case ResponseCode.AuthFailed:
                    break;
            }
            return message;
        }
    };

    const handleSignUp = async (): Promise<string | undefined> => {
        navigate(`${Path.SignUpPage}${queryString}`);
        return undefined;
    }

    const handleForgotPassword = async (): Promise<string | undefined> => {
        navigate(`${Path.ForgotPasswordPage}${queryString}`);
        return undefined;
    }

    function getInnerHeightInEm() {
        const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
        return window.innerHeight / fontSize;
    }
    
    // useEffect(() => {
    //     const fetchMyVoiceTeaser = async () => {
    //         try {
    //             const baseUrl = window.location.origin;
    //             const response = await axios.get(`${baseUrl}/SignIn/Teaser`);

    //             if (response.status === 200) {
    //                 switch(response.data.type) {
    //                     case 'HotSeat':
    //                         teaserContentRef.current = <HotSeatView data={response.data} />;
    //                         break;
    //                     case 'Feedback':
    //                         teaserContentRef.current = <FeedbackView data={response.data} />;
    //                         break;                                
    //                     case 'HeadToHead':
    //                         teaserContentRef.current = <HeadToHeadView data={response.data} />;
    //                         break;
    //                     case 'Spotlight':
    //                         teaserContentRef.current = <SpotlightView data={response.data} />;
    //                         break;                                                                  
    //                     case 'AddMyVoice':
    //                         teaserContentRef.current = <AddMyVoiceView data={response.data} />;
    //                         break;                                
    //                     } 
    //             } else { //TODO: remove test code
    //                 teaserContentRef.current = <HotSeatView data={response.data} />;
    //             }

    //         } catch(error) {

    //         }
    //     };

    //     fetchMyVoiceTeaser();
    // }, []);

    // function TeaserPanel() {
    //     return (
    //         <div id='teaserPanel'>
    //             {teaserContentRef.current}
    //         </div>
    //     );
    // }

    function TeaserItem() {
        return (
            <div id='teaserItem'>
                <img src='/img/teaser.png' width="84%" alt='Sample Content'/>
            </div>
        );
    }
   
    function LoginBlock() {
        return (
            <Column>
                <TextField ref={usernameRef} required={true} textInputType={TextInputType.username} placeholder={usernamePrompt}/>
                <PasswordField ref={passwordRef} required={true} placeholder={passwordPrompt}/>
                <StandardButton label={signInButtonText} working={stateSigningIn} action={handleSignIn}/>
                <Spacer size={smallSpacing}/> 
                <Row>
                    <Checkbox ref={keepMeSignedInRef} checked={true} label={keepMeSignedInText}/>
                    <TextButton label={forgotPasswordText} action={handleForgotPassword}/>
                </Row>   
            </Column>    
        );
    };

    return (
        <Screen>
            {/* <TeaserPanel/> */}
            <Panel spacing='3em'>
                <PageTitle title={titleText}/>
                {getInnerHeightInEm() > teaserItemBreakpoint ? <TeaserItem/> : null}
                <LoginBlock/>
                <Row center={true}><TextButton label={signUpButtonText} action={handleSignUp}/></Row>
                <StaticFooter title={footerText} caption={footerCaption}/>
            </Panel>
        </Screen>
    );
};
